import { Box, Typography } from "@mui/material";

export default function FooterView() {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#2C262D",
          height: "45px",
          bottom: 0,
          width: "100%",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 10,
          // width: "calc(100% - 70px)",
        }}
      >
        <Typography variant="body2">
          © 2024 Mirror 3D Lab Ltd - Darefore. All rights reserved.
        </Typography>
      </Box>
    </>
  );
}
