import convert from "convert-units";
import { Reducer, useCallback, useEffect, useMemo, useReducer } from "react";
import useAsyncState, { isFulfilled, isUnloaded } from "./Async";
import { Database } from "./DatabaseDefinitions";
import { useSession, useSupabase } from "src/contexts/SupabaseContext";
import dareforeSensor from "../assets/img/layout/coach/Library/Plans/DareforeSensor.svg";
import powerMSensor from "../assets/img/layout/coach/Library/Plans/PMSensor.svg";
import hrSensor from "../assets/img/layout/coach/Library/Plans/HRSensor.svg";
import dayjs from "dayjs";

export const panic = (msg: string) => {
  throw new Error(msg);
};

export type Fun<T1, T2> = (arg: T1) => T2;

export const withReference = <T1, T2>(val: T1, callback: Fun<T1, T2>): T2 =>
  callback(val);

export type SupabaseCall<T extends (...args: unknown[]) => unknown> = Awaited<
  ReturnType<T>
>;

export const userState = [
  "anon",
  "logged-in-cyclist",
  "logged-in-coach",
  "logged-in-admin",
  "missing-info",
  "pending",
  "profile-success",
  "coach-profile-success",
] as const;

export type UserState = typeof userState[number];

export const useUserAuthState = () => {
  const session = useSession();
  // const admin = useAdmin();
  const supabase = useSupabase();
  const admin =
    useAsyncState<Array<Database["public"]["Tables"]["admin"]["Row"]>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(admin)) {
      admin.fire(async () =>
        supabase
          .from("admin")
          .select()
          .throwOnError()
          .then((res) => {
            return res.data;
          }),
      );
    }
  }, []);

  const userState: UserState = useMemo(() => {
    if (!isFulfilled(session)) {
      return "pending";
    }

    if (!isFulfilled(admin)) {
      return "pending";
    }

    if (!session.result.data.session) {
      return "anon";
    }

    const isAdmin = admin.result.some(
      (user) => user.user_id === session.result.data.session.user.id,
    );
    if (isAdmin) return "logged-in-admin";

    const { name, accountType, showSuccess } =
      session.result.data.session.user?.user_metadata;

    if (!!name && !!accountType) {
      if (showSuccess === true) {
        if (accountType === "cyclist") {
          return "profile-success"
        }
        return "coach-profile-success"
      }
      return accountType === "cyclist"
        ? "logged-in-cyclist"
        : "logged-in-coach";
    }

    return "missing-info";
  }, [session, admin]);
  return userState;
};

export const zip = <T1, T2>(
  arr1: Array<T1>,
  arr2: Array<T2>,
): Array<[T1, T2]> => arr1.map((el, i) => [el, arr2[i]]);

export const splitAndCapitalize = (str: string) =>
  str.replaceAll(/([A-Z])/g, " $1").replaceAll(/^(.)/g, (v) => v.toUpperCase());

export const expertiseOptions = [
  "Road Cycling",
  "Mountain Biking",
  "Time Trial",
  "Paracycling",
  "Cyclocross",
  "Duathlon",
  "Triathlon",
];

export const allBikePositions = [
  "Standing",
  "Comfortable - Ideal",
  "Comfortable - Not Ideal",
  "Aggressive - Ideal",
  "Aggressive - Not Ideal",
  "TT - Ideal",
  "TT - Not Ideal",
  "Lower than TT",
] as const;

export type BikePosition = typeof allBikePositions[number];

export type TTBikePosition = Exclude<
  BikePosition,
  "Aggressive - Ideal" | "Aggressive - Not Ideal"
>;

export const ttBikePositions: Array<TTBikePosition> = [
  "Standing",
  "Comfortable - Ideal",
  "Comfortable - Not Ideal",
  "TT - Ideal",
  "TT - Not Ideal",
  "Lower than TT",
];

export type RoadBikePosition = BikePosition;

export const roadBikePositions: Array<RoadBikePosition> = [
  "Standing",
  "Comfortable - Ideal",
  "Comfortable - Not Ideal",
  "Aggressive - Ideal",
  "Aggressive - Not Ideal",
  "TT - Ideal",
  "TT - Not Ideal",
  "Lower than TT",
];

export const allColors: { [K in BikePosition]?: string } = {
  Standing: "#B5E254",
  "Comfortable - Ideal": "#36B37E",
  "Comfortable - Not Ideal": "#78b79c",
  "TT - Ideal": "#DD4F4A",
  "TT - Not Ideal": "#F4C7C5",
  "Aggressive - Ideal": "#4367e7",
  "Aggressive - Not Ideal": "#80a1d2",
  "Lower than TT": "#e3d024",
};

export const metersToImperial = (meters: number) => {
  const feet = Math.floor(convert(meters).from("m").to("ft"));
  const remainder = meters - convert(feet).from("ft").to("m");
  return {
    feet,
    inches: Math.round(convert(remainder).from("m").to("in")),
  };
};

export const imperialToMeters = (feet: number, inches?: number) =>
  convert(feet).from("ft").to("m") + convert(inches).from("in").to("m");

export const imperialToCentimeters = (feet: number, inches?: number) =>
  convert(feet).from("ft").to("cm") + convert(inches).from("in").to("cm");

export const powerZonesFromFTP = (maxFTP: number) =>
  [55, 75, 90, 105, 120, 138].map((val) => Math.round((val * maxFTP) / 100));

export const heartRateZonesFromMaxHeartRate = (maxHeartRate: number) =>
  [60, 70, 80, 90].map((val) => Math.round((val * maxHeartRate) / 100));

export const defaultPowerZones = [55, 75, 90, 105, 120, 138].map(
  (val) => val / 100,
);

export const defaultHeartRateZones = [60, 70, 80, 90].map((val) => val / 100);

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export type Zone = {
  min: number;
  max: number;
};

export const zoneEdgesToZoneType = (edges: Array<number>): Array<Zone> =>
  edges.length === 0
    ? []
    : edges
      .map((edge, i) => ({
        min: i === 0 ? 0 : edges[i - 1],
        max: edge - 1,
      }))
      .concat([{ min: edges.at(-1), max: undefined }]);

export const valueInZone = (val: number, zone: Zone): boolean => {
  if (val !== null)
    return zone.min === 0
      ? val <= zone.max
      : zone.max === undefined
        ? val >= zone.min
        : val >= zone.min && val <= zone.max;
};

export const timeFormatter = (valStr: string) => {
  const val = Number(valStr);
  const hours = Math.floor(Math.floor(val / 60) / 60);
  const minutes = Math.trunc((val % 3600) / 60);
  const seconds = Math.trunc(val % 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export type Join<K, P, D extends "." | ">" | "" = "."> = K extends
  | string
  | number
  ? P extends string | number
  ? `${K}${"" extends P ? "" : D}${P}`
  : never
  : never;

export type Paths<T> = T extends object
  ? {
    [K in keyof T]-?: K extends string | number
    ? Paths<T[K]> extends infer R
    ? `${K}` | Join<K, R>
    : never
    : never;
  }[keyof T]
  : "";

export type ArrayPaths<T> = T extends Array<infer S>
  ? ArrayPaths<S> extends infer R
  ? R extends ""
  ? ">"
  : ">" | Join<"", R, ">">
  : never
  : T extends object
  ? {
    [K in keyof T]-?: K extends string | number
    ?
    | `${K}`
    | Join<
      K,
      ArrayPaths<T[K]> extends infer R ? R : never,
      T[K] extends Array<any> ? "" : "."
    >
    : never;
  }[keyof T]
  : "";

type ArrayType = {
  this: string;
  that: {
    another: string;
    this: {
      thatt: {
        another: number[];
      }[];
    };
  }[];
}[];

export type Leaves<T> = T extends object
  ? {
    [K in keyof T]-?: Leaves<T[K]> extends infer R ? Join<K, R> : never;
  }[keyof T]
  : "";

export type ExtractPath<T, Path extends Paths<T>> = Path extends ""
  ? T
  : Path extends keyof T
  ? T[Path]
  : Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
  ? Rest extends Paths<T[Key]>
  ? ExtractPath<T[Key], Rest>
  : never
  : never
  : never;

export type Dotless<T extends string | number | bigint | boolean> =
  T extends `${any}.${any}` ? never : T;

export type ExtractPath2<T, Path extends ArrayPaths<T>> = Path extends ">" | ""
  ? T
  : Path extends keyof T
  ? T[Path]
  : Path extends `${Dotless<infer Key>}>${infer Rest}`
  ? Key extends ""
  ? T extends Array<infer S>
  ? Rest extends ArrayPaths<S>
  ? Array<ExtractPath2<S, Rest>>
  : never
  : never
  : Key extends keyof T
  ? T[Key] extends Array<infer S>
  ? Rest extends ArrayPaths<S>
  ? Array<ExtractPath2<S, Rest>>
  : Rest extends ""
  ? Array<S>
  : never
  : never
  : never
  : Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
  ? Rest extends ArrayPaths<T[Key]>
  ? ExtractPath2<T[Key], Rest>
  : never
  : never
  : never;

type Flatten<T> = T extends Array<infer S> ? Flatten<S> : T;

type ExtractFlatPath<T, Path extends ArrayPaths<T>> = Flatten<
  ExtractPath2<T, Path>
> extends infer R
  ? `${Path}>` extends ArrayPaths<T>
  ? Array<R>
  : R
  : never;

type inner = ExtractFlatPath<ArrayType, ">that>this.thatt>another">;

type GenerateArrayFilters<
  T,
  Path extends ArrayPaths<T>,
  Concat extends string = "",
> = Path extends ""
  ? {}
  : Path extends `${infer Key}>${infer Rest}`
  ? `${Key}>` extends ArrayPaths<T>
  ? ExtractFlatPath<T, `${Key}>`> extends infer PrefixType
  ? {
    [K in `${Concat}${Key}>`]: (datum: PrefixType) => boolean;
  } & (Rest extends ArrayPaths<PrefixType>
    ? GenerateArrayFilters<PrefixType, Rest, `${Concat}${Key}`>
    : {})
  : "first_never"
  : [T, Key]
  : {};

// type GenerateFiltersFromPath<
//   T,
//   Path extends ArrayPaths<T>,
//   Concat extends string = "",
// > = Path extends `${infer Key}>${infer Rest}`
//   ? Key extends ArrayPaths<T>
//     ? {
//         [K in Key]: (datum: ExtractFlatPath<T, Key>) => boolean;
//       } & GenerateFiltersFromPath<ExtractPath2<T, Key>, Rest>
//     : never
//   : {};

const lensMap = <T, Path extends ArrayPaths<T>, NewType>(
  object: T,
  path: Path,
  filters: GenerateArrayFilters<T, Path>,
  mappingFunction: (prev: ExtractFlatPath<T, Path>) => NewType,
) => undefined;

const arrayType: ArrayType = [
  {
    this: "stavros",
    that: [
      {
        another: "chryselis",
        this: {
          thatt: [
            {
              another: [1, 2, 3],
            },
          ],
        },
      },
    ],
  },
];

// lensMap(arrayType, ">that>this.thatt>another", {

// }, (numbers) =>
//   numbers.map((num) => num + 1),
// );
// type tt = ExtractPath2<ArrayType, ">that">;

export type OmitPath<T, Path extends Paths<T>> = Path extends keyof T
  ? Omit<T, Path>
  : Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
  ? Rest extends Paths<T[Key]>
  ? { [K in keyof T]: K extends Key ? OmitPath<T[Key], Rest> : T[Key] }
  : never
  : never
  : never;

export type InjectPath<
  T,
  Path extends string | number | symbol,
  NewType,
> = Path extends keyof T
  ? { [K in keyof T]: K extends Path ? NewType : T[K] }
  : Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
  ? { [K in keyof T]: K extends Key ? InjectPath<T[K], Rest, NewType> : T[K] }
  : {
    [K in keyof T | Key]: K extends Key
    ? InjectPath<{}, Rest, NewType>
    : K extends keyof T
    ? T[K]
    : never;
  }
  : { [K in keyof T | Path]: K extends keyof T ? T[K] : NewType };

export const splitAt = (str: string, delimiter: string): [string, string] =>
  withReference(str.indexOf(delimiter), (index) =>
    index === -1
      ? [str, ""]
      : [str.substring(0, index), str.substring(index + 1)],
  );

export const lensGet2 = <T, Path extends ArrayPaths<T>>(
  object: Text,
  path: Path,
): ExtractPath2<T, Path> => {
  if (path === "") {
    return object as any;
  }

  const [head, rest] = splitAt(path, ">");

  if (head === path) {
    return;
  }
};

export const useMean = () => {
  const medianReducer: Reducer<[number, number], number> = useCallback(
    ([sum, count], num) => [sum + num, count + 1],
    [],
  );

  const [[sum, count], dispatch] = useReducer(medianReducer, [0, 0]);

  const median = useMemo(() => (count === 0 ? 0 : sum / count), [sum, count]);

  return [median, dispatch] as [number, typeof dispatch];
};

export const getSecondsFromHHMMSS = (value) => {
  const [str1, str2, str3] = value.split(":");

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

export const toHHMMSS = (secs) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    //.filter((val, index) => val !== "00" || index > 0)
    .join(":")
    .replace(/^0/, "");
};

export const sensorList = [
  { id: "darefore", name: "Darefore Sensor", icon: dareforeSensor },
  { id: "power-meter", name: "Power Meter Sensor", icon: powerMSensor },
  { id: "heart-rate", name: "Heart Rate Sensor", icon: hrSensor },
];

export const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

const POWER_IF = [0.45, 0.65, 0.825, 0.975, 1.13, 1.295, 1.5]
const HR_IF = [0.75, 0.875, 0.925, 0.975, 1.06]

export const TSSEstimation = (type: string, value: number, seconds: number) => {
  switch (type) {
    case 'Power':
      return seconds * (value / 100) / 36
    case 'Power Zones':
      return seconds * POWER_IF[value] / 36
    case 'Heart Rate Zones':
      return seconds * HR_IF[value] / 36
    default:
      return 0;
  }
}

export const complianceStepColors = {
  "todo": "#4367E7",
  "nothing": "#3b3b3b",
  "target meet": "#36B37E",
  "skipped": "#F1522F",
}

export const compliancePercentage = (sessions: { date: string, total_time: number, tss?: number }[], workouts: { date: string, duration: number, tss?: number }[]) => {
  const workoutTargets = new Map<string, { totalDuration: number; totalTSS?: number }>();

  for (const workout of workouts) {
    const now = dayjs()
    const date = dayjs(workout.date).format('YYYY-MM-DD');
    if (dayjs(workout.date).isAfter(now, 'day')) {
      break;
    }
    if (!workoutTargets.has(date)) {
      workoutTargets.set(date, { totalDuration: 0, totalTSS: 0 });
    }
    const target = workoutTargets.get(date)!;
    target.totalDuration += workout.duration;
    if (workout.tss !== undefined) {
      target.totalTSS = (target.totalTSS ?? 0) + workout.tss;
    }
  }

  let sessionPointer = 0;
  let fulfilledDays = 0;
  const workoutDates = Array.from(workoutTargets.keys());
  const totalDays = workoutDates.length;

  for (const date of workoutDates) {
    const workoutTarget = workoutTargets.get(date)!;
    const workoutDate = dayjs(date);

    let totalSessionTime = 0;
    let totalSessionTSS = 0;

    while (sessionPointer < sessions.length && dayjs(sessions[sessionPointer].date).isSameOrBefore(workoutDate, 'day')) {
      if (dayjs(sessions[sessionPointer].date).isSame(workoutDate, 'day')) {
        totalSessionTime += sessions[sessionPointer].total_time / 1000;
        if (sessions[sessionPointer].tss !== undefined) {
          totalSessionTSS += sessions[sessionPointer].tss;
        }
      }
      sessionPointer++;
    }

    const meetsDurationTarget = totalSessionTime >= 0.75 * workoutTarget.totalDuration;
    const meetsTSS_Target = workoutTarget.totalTSS === undefined || totalSessionTSS >= 0.75 * workoutTarget.totalTSS;

    if (meetsDurationTarget && meetsTSS_Target) {
      fulfilledDays++;
    }
  }

  const compliance = totalDays ? (fulfilledDays / totalDays) * 100 : 0;
  console.log(fulfilledDays, totalDays)
  return Number(compliance.toFixed(2));
}