import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Slider,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession, useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, mapAsyncState } from "src/utils/Async";
import {
  defaultHeartRateZones,
  defaultPowerZones,
  heartRateZonesFromMaxHeartRate,
  powerZonesFromFTP,
  SupabaseCall,
  withReference,
} from "src/utils/common";
import * as yup from "yup";
import infoicon from "../../assets/img/views/profile-setup/info-icon.svg";
import slider from "../../assets/img/views/profile-setup/slider.svg";
import { PersonalSetupInfoType } from "./PersonalSetupInfo";

const CustomModal = styled(Modal)(({ theme }) => ({
  width: "36.625rem",
  height: "24.625rem",
  backgroundColor: "#3D343E",
  color: "#F7F3F0",
  border: "1px solid #F7F3F0",
  borderRadius: "1.25rem",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "hidden",
}));

const DisabledSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: ".8125rem 0",
  width: "20.625rem",
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: ".0625rem solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 .5rem rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: "gray",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

export type AthleteSetupInfoType = {
  max_heart_rate: number;
  lthr: number;
  ftp: number;
  trainingGoals: Array<number>;
  levelOfExperience: number;
  heart_rate_zones: Array<number>;
  power_zones: Array<number>;
};

export type AthleteSetupInfoProps = {
  onChange: (currentAthleteSetupInfo: AthleteSetupInfoType) => void;
  onBack: () => void;
  initialValues?: AthleteSetupInfoType;
  personalSetupInfo: PersonalSetupInfoType;
  experienceLevel: Array<[number, string]>;
  trainingGoals: Array<[number, string]>;
  imageFile: File;
};

export default function AthleteSetupInfo(props: AthleteSetupInfoProps) {
  const defaultMaxHeartRate = useMemo(
    () =>
      220 -
      (new Date().getFullYear() -
        props.personalSetupInfo.dateofbirth.getFullYear()),
    [props.personalSetupInfo.dateofbirth],
  );

  const defaultFtp = useMemo(
    () => (props.personalSetupInfo.gender === "male" ? 214 : 145),
    [props.personalSetupInfo.gender],
  );

  const formik = useFormik<AthleteSetupInfoType>({
    initialValues: props.initialValues ?? {
      max_heart_rate: defaultMaxHeartRate,
      lthr: null,
      ftp: defaultFtp,
      trainingGoals: [],
      levelOfExperience: null,
      heart_rate_zones: [],
      power_zones: [],
    },
    validationSchema: yup.object().shape({
      max_heart_rate: yup
        .number()
        .nullable()
        .required("Max Heart Rate is required")
        .min(60, "Max heart rate cannot be lower than 60bpm")
        .max(230, "Max heart rate cannot be greater than 230bpm")
        .test(
          "Maximum heart rate is required",
          "Field is required",
          (value) => !!value,
        ),
      lthr: yup
        .number()
        .nullable()
        .min(60, "LTHR cannot be lower than 60bpm")
        .max(230, "LTHR rate cannot be greater than 230bpm"),
      ftp: yup
        .number()
        .nullable()
        .required("FTP is required")
        .min(50, "FTP cannot be lower than 50W")
        .max(600, "FTP cannot be greater than 600W")
        .test("FTP is required", "FTP is required", (value) => !!value),
      levelOfExperience: yup
        .number()
        .required("Level of Experience is required")
        .oneOf(
          props.experienceLevel.map((level) => level[0]).concat([null]),
          "Please select a valid level of experience",
        ),
      trainingGoals: yup
        .array()
        .test(
          "training goals are valid",
          "Please select valid goals",
          (values) =>
            values.every((val) =>
              props.trainingGoals.some((goal) => goal[0] === val),
            ),
        ), // HETA REDUCTION
    }),
    onSubmit: (values) => {
      if ("fire" in submitCall) {
        submitCall.fire(async () => submitPromise(values));
      }
    },
  });

  useEffect(() => {
    props.onChange(formik.values);
  }, [formik.values]);

  const heartRateMarks = useMemo(() => {
    if (
      !formik.values.heart_rate_zones ||
      formik.values.max_heart_rate === 0 ||
      (formik.values.max_heart_rate as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = formik.values.max_heart_rate * 0.5;
    for (const val of formik.values.heart_rate_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + formik.values.max_heart_rate) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [formik.values.heart_rate_zones, formik.values.max_heart_rate]);

  const powerMarks = useMemo(() => {
    if (
      !formik.values.power_zones ||
      formik.values.ftp === 0 ||
      (formik.values.ftp as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = 0;
    for (const val of formik.values.power_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + formik.values.ftp * 1.5) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [formik.values.power_zones, formik.values.ftp]);

  const heart_rate_zones_colors = useMemo(
    () =>
      withReference(formik.values.max_heart_rate / 2, (halfHR) =>
        formik.values.heart_rate_zones.map(
          (zone) => ((zone - halfHR) / halfHR) * 100,
        ),
      ),
    [formik.values.heart_rate_zones, formik.values.max_heart_rate],
  );

  const power_zones_colors = useMemo(
    () =>
      formik.values.power_zones.map(
        (zone) => (zone / (formik.values.ftp * 1.5)) * 100,
      ),
    [formik.values.power_zones, formik.values.ftp],
  );

  useEffect(() => {
    formik.setFieldValue(
      "heart_rate_zones",
      heartRateZonesFromMaxHeartRate(formik.values.max_heart_rate),
    );
  }, [formik.values.max_heart_rate, formik.touched.max_heart_rate]);

  useEffect(() => {
    formik.setFieldValue("power_zones", powerZonesFromFTP(formik.values.ftp));
  }, [formik.values.ftp, formik.touched.ftp]);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const supabase = useSupabase();

  const sessionData = useSession();

  const userMetaData = mapAsyncState(
    sessionData,
    (session) => session?.data?.session?.user,
  );

  const skipForLaterPromise = useCallback(
    () =>
      isFulfilled(userMetaData)
        ? supabase.storage
            .from("avatars")
            .upload(`${userMetaData.result.id}/avatar`, props.imageFile, {
              upsert: true,
            })
            .then((image) =>
              supabase.auth
                .updateUser({
                  data: {
                    name: props.personalSetupInfo.name,
                    accountType: "cyclist",
                    showSuccess: true,
                  },
                })
                .then((_) =>
                  supabase.from("athlete").insert({
                    user_id: userMetaData.result.id,
                    image: image.error
                      ? undefined
                      : `${userMetaData.result.id}/avatar`,
                    name: props.personalSetupInfo.name,
                    dob: props.personalSetupInfo.dateofbirth.toISOString(),
                    height: props.personalSetupInfo.height,
                    weight: props.personalSetupInfo.weight,
                    gender: props.personalSetupInfo.gender !== "male",
                    country_code:
                      userMetaData?.result?.user_metadata?.country ?? "n/a",
                    units: props.personalSetupInfo.units !== "metric",
                    max_heart_rate: defaultMaxHeartRate,
                    ftp: defaultFtp,
                    heart_rate_zones: defaultHeartRateZones,
                    power_zones: defaultPowerZones,
                    experience_level: 1,
                  }),
                )
                .then((_) =>
                  supabase
                    .from("user_token")
                    .insert({
                      user_id: userMetaData.result.id,
                    })
                    .throwOnError(),
                ),
            )
        : null,
    [supabase, userMetaData, props.personalSetupInfo],
  );

  useEffect(() => {
    console.log(props.imageFile);
  }, [props.imageFile]);

  const submitPromise = useCallback(
    (values: AthleteSetupInfoType) =>
      isFulfilled(userMetaData)
        ? supabase.storage
            .from("avatars")
            .upload(`${userMetaData.result.id}/avatar`, props.imageFile, {
              upsert: true,
            })

            .then((image) =>
              supabase.auth
                .updateUser({
                  data: {
                    name: props.personalSetupInfo.name,
                    accountType: "cyclist",
                    showSuccess: true,
                  },
                })
                .then((_) =>
                  supabase
                    .from("athlete")
                    .insert({
                      user_id: userMetaData.result.id,
                      image: image.error
                        ? undefined
                        : `${userMetaData.result.id}/avatar`,
                      email: userMetaData.result.email,
                      name: props.personalSetupInfo.name,
                      dob: props.personalSetupInfo.dateofbirth.toISOString(),
                      height: props.personalSetupInfo.height,
                      weight: props.personalSetupInfo.weight,
                      gender: props.personalSetupInfo.gender !== "male",
                      country_code:
                        userMetaData?.result?.user_metadata?.country ?? "n/a",
                      units: props.personalSetupInfo.units !== "metric",
                      max_heart_rate: values.max_heart_rate,
                      ftp: values.ftp,
                      heart_rate_zones: values.heart_rate_zones.map(
                        (value) => value / values.max_heart_rate,
                      ),
                      power_zones: values.power_zones.map(
                        (value) => value / values.ftp,
                      ),
                      lthr: values.lthr,
                      experience_level: values.levelOfExperience,
                    })
                    .throwOnError()
                    .then((_) =>
                      supabase
                        .from("athlete_training_goals")
                        .insert(
                          values.trainingGoals.map((goal) => ({
                            athlete_id: userMetaData.result.id,
                            training_goal_id: goal,
                          })),
                        )
                        .throwOnError()
                        .then((_) =>
                          supabase
                            .from("user_token")
                            .insert({
                              user_id: userMetaData.result.id,
                            })
                            .throwOnError(),
                        ),
                    ),
                ),
            )
        : null,
    [supabase, userMetaData, props.personalSetupInfo],
  );

  const skipForLaterCall =
    useAsyncState<SupabaseCall<typeof skipForLaterPromise>>();

  const submitCall = useAsyncState<SupabaseCall<typeof submitPromise>>();

  const navigate = useNavigate();

  useEffect(() => {
    if (isFulfilled(skipForLaterCall) && "unload" in sessionData) {
      skipForLaterCall.unload();
      sessionData.unload();
      navigate("/account/profile-complete");
    }
  }, [skipForLaterCall]);

  useEffect(() => {
    if (isFulfilled(submitCall) && "unload" in sessionData) {
      submitCall.unload();
      sessionData.unload();
      navigate("/account/profile-complete");
    }
  }, [submitCall]);

  const isMobileScreen = useMediaQuery("(max-width:450px)");

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box marginTop="40px">
          <Box>
            <Typography
              variant="h1"
              color="text.secondary"
              sx={{ mb: ".5rem" }}
            >
              Profile Setup
            </Typography>
            <Typography
              variant="body1"
              fontSize={"13px"}
              lineHeight={"15px"}
              pl={".1875rem"}
            >
              Athlete Information
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1.5rem",
              mt: "16px",
            }}
          >
            <TextField
              error={
                formik.touched.max_heart_rate &&
                Boolean(formik.errors.max_heart_rate)
              }
              helperText={
                formik.touched.max_heart_rate
                  ? formik.errors.max_heart_rate
                  : formik.values.max_heart_rate === defaultMaxHeartRate
                  ? "This value is an estimation based on your age. Please enter your own."
                  : null
              }
              FormHelperTextProps={{
                style: {
                  color: !formik.touched.max_heart_rate ? "#64605D" : "red",
                  fontSize: isMobileScreen ? "8px" : "",
                },
              }}
              label="Max Heart Rate (BPM) *"
              name="max_heart_rate"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              value={formik.values.max_heart_rate}
              variant="outlined"
            />
            <TextField
              error={formik.touched.lthr && Boolean(formik.errors.lthr)}
              helperText={formik.touched.lthr && formik.errors.lthr}
              label="LTHR (BPM)"
              name="lthr"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              value={formik.values.lthr ?? ""}
              variant="outlined"
            />
            <TextField
              error={formik.touched.ftp && Boolean(formik.errors.ftp)}
              helperText={
                formik.touched.ftp
                  ? formik.errors.ftp
                  : formik.values.ftp === defaultFtp
                  ? "This value is an estimation based on your gender. Please enter your own."
                  : null
              }
              FormHelperTextProps={{
                style: {
                  color: !formik.touched.ftp ? "#64605D" : "red",
                  fontSize: isMobileScreen ? "8px" : "",
                },
              }}
              label="FTP (W) *"
              name="ftp"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              value={formik.values.ftp ?? ""}
              variant="outlined"
            />
            <FormControl fullWidth>
              <InputLabel id="trainingGoal">Training Goal</InputLabel>
              <Select
                id="trainingGoal"
                name="trainingGoal"
                label="Training Goal"
                value={formik.values.trainingGoals || []}
                multiple
                onChange={(e) =>
                  formik.setFieldValue("trainingGoals", e.target.value)
                }
                renderValue={(selected) =>
                  selected
                    .map((k) => props.trainingGoals.find((tg) => tg[0] === k))
                    .sort()
                    .map((el) => el[1])
                    .join(", ")
                }
              >
                {props.trainingGoals.map((goal) => (
                  <MenuItem key={goal[0]} value={goal[0]}>
                    <Checkbox
                      checked={
                        formik.values.trainingGoals?.includes(goal[0]) ?? false
                      }
                    />
                    <ListItemText primary={goal[1]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              error={
                formik.touched.levelOfExperience &&
                Boolean(formik.errors.levelOfExperience)
              }
            >
              <InputLabel
                id="levelOfExperience"
                error={
                  formik.touched.levelOfExperience &&
                  Boolean(formik.errors.levelOfExperience)
                }
              >
                Level of Experience*
              </InputLabel>
              <Select
                id="levelOfExperience"
                name="levelOfExperience"
                value={formik.values.levelOfExperience ?? ""}
                onChange={formik.handleChange}
                label="Level of Experience"
                error={
                  formik.touched.levelOfExperience &&
                  Boolean(formik.errors.levelOfExperience)
                }
              >
                {props.experienceLevel.map((level) => (
                  <MenuItem key={level[0]} value={level[0]}>
                    {level[1]}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.levelOfExperience &&
                formik.touched.levelOfExperience && (
                  <Typography
                    fontSize="10px !important"
                    color={"red"}
                    mt={"4px"}
                  >
                    Level of Experience is required
                  </Typography>
                )}
            </FormControl>
          </Box>

          <Box sx={{ mt: "16.3504px" }}>
            {withReference(
              Boolean(formik.errors.max_heart_rate) ||
                !formik.values.max_heart_rate ||
                formik.values.max_heart_rate === 0 ||
                (formik.values.max_heart_rate as any) === "",
              (disabled) => (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="h4"
                        color="text.primary"
                        sx={{ opacity: "0.5" }}
                      >
                        Heart Rate Zones (BPM)
                      </Typography>
                      <Tooltip
                        title="Your HR training zones are determined using the most common method, i.e. on the % of your maximum heart rate. You can always customize your zones."
                        placement="right"
                        arrow
                      >
                        <img
                          src={infoicon}
                          alt="info_icon"
                          width="10"
                          height="11"
                        />
                      </Tooltip>
                    </Box>

                    {disabled ? (
                      <Typography
                        variant="h4"
                        color="text.primary"
                        sx={{ opacity: "0.3" }}
                      >
                        (Set Max Heart Rate to unlock)
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                  {!disabled ? (
                    <Slider
                      track={false}
                      min={Math.floor(0.5 * formik.values.max_heart_rate)}
                      max={Number(formik.values.max_heart_rate)}
                      disableSwap
                      valueLabelDisplay="on"
                      marks={heartRateMarks}
                      step={1}
                      value={formik.values.heart_rate_zones}
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent",
                          color: "#80822B",
                          margin: 0,
                          fontSize: "10px",
                          marginTop: "5px",
                        },
                        marginTop: "1.25rem",
                        height: "9.5296px",
                        "& .MuiSlider-rail": {
                          background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[2]}% , #E28E54 ${heart_rate_zones_colors[2]}%, #E28E54 ${heart_rate_zones_colors[3]}%, #DD4F4A ${heart_rate_zones_colors[3]}%, #DD4F4A 100%)`,
                          opacity: 1,
                        },
                        "& .MuiSlider-thumb": {
                          backgroundImage: `url(${slider})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "none",
                          height: ".5rem",
                          width: ".5625rem",
                          boxShadow: "none",
                          top: ".625rem",
                        },
                        "& .MuiSlider-mark": {
                          opacity: 0,
                        },
                        "& .MuiSlider-markLabel": {
                          fontSize: "10px",
                          color: "#F7F3F0",
                        },
                      }}
                      name="heart_rate_zones"
                      onChange={(_, values) =>
                        formik.setFieldValue("heart_rate_zones", values)
                      }
                    />
                  ) : (
                    <div style={{ pointerEvents: "none" }}>
                      <DisabledSlider defaultValue={[]} />
                    </div>
                  )}
                </Box>
              ),
            )}
            {withReference(
              Boolean(formik.errors.ftp) ||
                !formik.values.ftp ||
                formik.values.ftp === 0 ||
                (formik.values.ftp as any) === "",
              (disabled) => (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="h4"
                        color="text.primary"
                        sx={{ opacity: "0.5" }}
                      >
                        Power Zones (W)
                      </Typography>

                      <Tooltip
                        title="Your Power training zones are determined using the most common method, i.e. on the % of your FTP. You can always customize your zones."
                        placement="right"
                        arrow
                      >
                        <img
                          src={infoicon}
                          alt="info_icon"
                          width="10"
                          height="11"
                        />
                      </Tooltip>
                    </Box>
                    {disabled ? (
                      <Typography
                        variant="h4"
                        color="text.primary"
                        sx={{ opacity: "0.3" }}
                      >
                        (Set FTP to unlock)
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                  {!disabled ? (
                    <Slider
                      key={`pwr-${formik.values.ftp}`}
                      track={false}
                      min={0}
                      max={(formik.values.ftp * 150) / 100}
                      disableSwap
                      valueLabelDisplay="on"
                      marks={powerMarks}
                      step={1}
                      value={formik.values.power_zones}
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent",
                          color: "#80822B",
                          margin: 0,
                          fontSize: "10px",
                          marginTop: "5px",
                        },
                        marginTop: "1.25rem",
                        height: "9.5296px",
                        "& .MuiSlider-rail": {
                          background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[3]}% , #E28E54 ${power_zones_colors[3]}%, #E28E54 ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[5]}%, #EA366F ${power_zones_colors[5]}%, #EA366F 100%)`,
                          opacity: 1,
                        },
                        "& .MuiSlider-thumb": {
                          backgroundImage: `url(${slider})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "none",
                          height: ".5rem",
                          width: ".5625rem",
                          boxShadow: "none",
                          top: ".625rem",
                        },
                        "& .MuiSlider-mark": {
                          opacity: 0,
                        },
                        "& .MuiSlider-markLabel": {
                          fontSize: "10px",
                          color: "#F7F3F0",
                        },
                      }}
                      name="power_zones"
                      onChange={(_, values) =>
                        formik.setFieldValue("power_zones", values)
                      }
                    />
                  ) : (
                    <div style={{ pointerEvents: "none" }}>
                      <DisabledSlider defaultValue={[]} />
                    </div>
                  )}
                </Box>
              ),
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "22px",
          }}
        >
          <Button
            onClick={props.onBack}
            size="small"
            variant="outlined"
            sx={{ width: "156px", lineHeight: "16px" }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            size="small"
            type="submit"
            sx={{ width: "156px", lineHeight: "16px", ml: "16px" }}
          >
            Submit
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mt: "20px",
              mb: "20px",
              textDecoration: "underline",
              cursor: "pointer",
              color: "#F7F3F0",
            }}
            onClick={handleOpen}
          >
            Skip for later
          </Typography>
          <CustomModal
            open={open}
            aria-describedby="Modal-description"
            disablePortal
          >
            <>
              <Box
                sx={{
                  padding: "3em",
                }}
              >
                <Typography
                  id="modal-modal-description"
                  variant="h4"
                  textAlign={"center"}
                  mb={2}
                >
                  If you're not sure about your Max Heart rate, FTP, or Heart
                  Rate and Power zones, don't worry - we'll fill in some average
                  values based on your age and gender. You can always edit this
                  information later on.
                </Typography>
                <Typography
                  id="modal-modal-description"
                  variant="h4"
                  textAlign={"center"}
                >
                  If you'd like us to use these average values, please confirm
                  below. If you have your own data, feel free to provide it now.
                  Remember, this information is important for optimising your
                  training and ensuring you get the most out of our application.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleClose}
                  variant="text"
                  sx={{ width: "100px", height: "38px", padding: "22px" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    width: "100px",
                    height: "38px",
                    padding: "22px",
                    ml: "1rem",
                  }}
                  onClick={(_) =>
                    "fire" in skipForLaterCall &&
                    skipForLaterCall.fire(async () => skipForLaterPromise())
                  }
                >
                  Confirm
                </Button>
              </Box>
            </>
          </CustomModal>
        </Box>
      </form>
    </>
  );
}
