import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import logo from "../../assets/img/views/lock/logoLock.svg";
import { useNavigate } from "react-router-dom";

export type LockScreenProps = {
	title: string;
};

export default function LockScreenTrendCard(props: LockScreenProps) {
	const isMobileScreen = useMediaQuery("(max-width:480px)");
	const navigate = useNavigate();
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					borderRadius: "6px",
					alignItems: "center",
					width: isMobileScreen ? "98%" : "30%",

					background: "linear-gradient(125deg, #3D343E 11.69%, #272727 55.18%)",
					backdropFilter: "blur(6px)",
					border: "2px solid #DD4F4A",
					justifyContent: "center",
				}}
			>
				<Box
					style={{
						alignItems: "center",
						flexGrow: 1,
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						gap: "8px",
					}}
				>
					<img
						src={logo}
						alt="lock-logo"
						height="50px"
						width="50px"
						style={{ alignSelf: "center" }}
					/>
					<Typography
						sx={{
							fontSize: ".9375rem",
							fontWeight: 700,
							textAlign: "center",
						}}
					>
						{props.title}
					</Typography>
				</Box>

				<Button
					variant="contained"
					size="small"
					sx={{
						alignSelf: "center",
						marginTop: "auto",
						mb: "16px",
					}}
					onClick={() => navigate("/dashboard/settings/subscription")}
				>
					Upgrade
				</Button>
			</Box>
		</>
	);
}
