import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Grid,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import type { TableRow } from "src/contexts/CacheContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import MyCoach from "src/content/customComponents/MyCoach";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import { useSnackbar } from "notistack";
import infoicon from "../../../assets/img/views/profile-setup/info-icon.svg";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import CoachRequestCard from "src/content/customComponents/CoachRequestCard";

export type MyCoachViewProps = {
	athlete: TableRow<"athlete">;
	coach: Array<TableRow<"coach">>;
	countries: Array<TableRow<"country">>;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MyCoachView(props: MyCoachViewProps) {
	const supabase = useSupabase();
	const user = useUser();
	const { enqueueSnackbar } = useSnackbar();
	const [coachEmail, setCoachEmail] = useState("");
	const [foundCoach, setFouncCoach] = useState<{
		country_code: string;
		email: string;
		expertise: string[];
		gender: boolean;
		name: string;
		other_info: string | null;
		phone: string | null;
		qualifications: string;
		units: boolean;
		user_id: string;
		image: string | null;
	}>();

	// Coach Related

	const coachExistPromise = useCallback(() => {
		// Start with fetching from the "coach_athletes" table
		return supabase
			.from("coach_athletes")
			.select("*")
			.eq("athlete_id", user.id)
			.eq("status", "Accepted")
			.then((res) => {
				if (res.error) {
					console.error("Error fetching coach_athletes:", res.error);
					return [];
				}

				if (res.data && res.data.length > 0) {
					const coachId = res.data[0].coach_id;
					return supabase
						.from("coach")
						.select("*")
						.eq("user_id", coachId)
						.then((res) => {
							if (res.error) {
								console.error("Error fetching coach:", res.error);
								return [];
							}
							return res.data ?? [];
						});
				}
				return [];
			});
	}, [supabase]);

	const coachExistCall =
		useAsyncState<SupabaseCall<typeof coachExistPromise>>();

	useEffect(() => {
		if (isUnloaded(coachExistCall)) {
			coachExistCall.fire(async () => coachExistPromise());
		}
	}, []);

	const findCoach = useCallback(
		(email: string) => {
			const coach = props.coach.find((coach) => coach.email === email);
			if (coach) setFouncCoach(coach);
			return coach;
		},
		[props.coach],
	);

	const coachConnectionPromise = useCallback(async () => {
		return supabase.from("coach_athletes").insert({
			coach_id: foundCoach.user_id,
			athlete_id: user.id,
			from_user: true,
			status: "Pending",
		});
	}, [supabase, user, foundCoach]);

	const coachConnectionCall =
		useAsyncState<SupabaseCall<typeof coachConnectionPromise>>();

	const sendConnectionNotificationPromise = useCallback(
		async (coachId: string) => {
			return supabase.from("notifications").insert({
				type: "Connection Request",
				content: {
					description: `Athlete ${user.user_metadata.name} (${user.email}) wants to connect with you.`,
				},
				user_id: coachId,
			});
		},
		[supabase, user],
	);

	const sendConnectionNotificationCall =
		useAsyncState<SupabaseCall<typeof sendConnectionNotificationPromise>>();

	useEffect(() => {
		if (
			isFulfilled(sendConnectionNotificationCall) &&
			isFulfilled(coachConnectionCall)
		) {
			if (sendConnectionNotificationCall.result) {
				enqueueSnackbar("Connection request sent!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "success",
					autoHideDuration: 2000,
				});
			} else {
				enqueueSnackbar("No coach found!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	}, [sendConnectionNotificationCall, coachConnectionCall]);

	// Coach Avatar
	const coachAvatar = useMemo(() => {
		if (foundCoach?.image) {
			return supabase.storage.from("avatars").getPublicUrl(foundCoach.image, {
				transform: {
					width: 150,
					height: 150,
				},
			}).data.publicUrl;
		}
		return createAvatar(initials, {
			size: 150,
			radius: 55,
			seed: foundCoach?.name,
		}).toDataUriSync();
	}, [foundCoach]);

	// Incoming Request
	const coachIncomingPromise = useCallback(
		() =>
			supabase
				.from("coach_athletes")
				.select("*,coach(*)")
				.eq("athlete_id", user.id)
				.eq("from_user", false)
				.eq("status", "Pending")
				.then((res) => res.data),
		[supabase],
	);

	const coachIncomingCall =
		useAsyncState<SupabaseCall<typeof coachIncomingPromise>>();

	useEffect(() => {
		if (isUnloaded(coachIncomingCall)) {
			coachIncomingCall.fire(async () => coachIncomingPromise());
		}
	}, [coachIncomingPromise, coachIncomingCall]);

	return (
		<>
			<Typography sx={{ fontSize: ".8125rem" }}>COACH REQUESTS</Typography>
			{isFulfilled(coachIncomingCall) &&
				(coachIncomingCall.result.length > 0 ? (
					<Grid container gap={1} mt={"1rem"}>
						{coachIncomingCall.result.map((user) => (
							<Grid
								item
								xl={1.5}
								lg={2.7}
								md={5}
								xs={8}
								key={user.id}
								sx={{ color: "#F7F3F0", textDecoration: "none" }}
							>
								<CoachRequestCard
									coach={user.coach}
									countries={props.countries}
								/>
							</Grid>
						))}
					</Grid>
				) : (
					<Typography>No Requests have been found.</Typography>
				))}
			{isFulfilled(coachExistCall) && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						mt: "32px",
					}}
				>
					<Box sx={{ display: "flex" }}>
						<Typography sx={{ fontSize: ".8125rem" }}>FIND COACH</Typography>
						<Tooltip
							title="You can be connected to only one coach."
							placement="right"
							arrow
						>
							<img src={infoicon} alt="info_icon" width="10" height="11" />
						</Tooltip>
					</Box>
					<Autocomplete
						disablePortal
						options={props.coach}
						value={
							coachEmail
								? props.coach.find((coach) => coach.email === coachEmail)
								: null
						}
						sx={{ width: 300 }}
						onChange={(event, newValue) => {
							if (typeof newValue === "object" && newValue !== null) {
								setCoachEmail(newValue.email);
							} else {
								setCoachEmail("");
							}
						}}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{`${option?.name}-${option.email}`}
							</li>
						)}
						renderInput={(params) => <TextField {...params} label="Coaches" />}
						getOptionLabel={(option) => {
							if (typeof option === "string") {
								return option;
							}
							return `${option.email}`;
						}}
					/>
					<Button
						variant="text"
						size="small"
						sx={{ width: 300 }}
						onClick={() => findCoach(coachEmail)}
						disabled={coachExistCall.result.length > 0}
					>
						Search
					</Button>
					{coachExistCall.result.length > 0 ? (
						<Box width={"15%"}>
							<MyCoach
								coach={coachExistCall.result[0]}
								countries={props.countries}
							/>
						</Box>
					) : (
						foundCoach && (
							<Box
								sx={{
									display: "flex",
									backgroundColor: "#2C262D",
									borderRadius: "4px",
									border: "1px solid #BC6D29",
									padding: "1.5em",
									gap: 2,
									alignItems: "center",
									justifyContent: "space-between",
									alignSelf: "flex-start"
								}}
							>
								<Box
									sx={{
										display: "flex",
										gap: 2,
										alignItems: "center",
									}}
								>
									<img
										src={coachAvatar}
										alt="avatar"
										style={{
											borderRadius: "50%",
											width: "80px",
											height: "80px",
											objectFit: "cover",
										}}
									/>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography fontSize={".875rem"}>
											{foundCoach.name}
										</Typography>
										<Typography fontSize={".75rem"}>Coach</Typography>
										<Typography fontSize={".625rem"} sx={{ opacity: 0.5 }}>
											{
												props.countries.find(
													(country) => country.code === foundCoach.country_code,
												).name
											}
										</Typography>
									</Box>
								</Box>
								<Button
									variant="contained"
									size="small"
									// sx={{ alignSelf: "flex-end" }}
									onClick={() => {
										if ("fire" in coachConnectionCall) {
											coachConnectionCall.fire(async () =>
												coachConnectionPromise(),
											);
										}
										if ("fire" in sendConnectionNotificationCall) {
											sendConnectionNotificationCall.fire(async () =>
												sendConnectionNotificationPromise(foundCoach.user_id),
											);
										}
									}}
								>
									Connect
								</Button>
							</Box>
						)
					)}
				</Box>
			)}
		</>
	);
}
