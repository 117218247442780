import { Box, useTheme } from "@mui/material";
import StatusBar from "../customComponents/dashboard/StatusBar";
import Footer from "../customComponents/dashboard/Footer";
import { Outlet } from "react-router-dom";
import Navbar from "../customComponents/dashboard/Navbar";
import { createContext, useContext, useState } from "react";
import Sidebar from "../customComponents/dashboard/Sidebar";

export const TitleContext =
  createContext<[string, (newTitle: string) => void]>(undefined);

export const useTitle = () => useContext(TitleContext);

export default function DashboardLayout() {
  const [title, setTitle] = useState<string>("Welcome");
  const theme = useTheme();


  return (
    <>
      <Navbar />
      <Sidebar />
      <Box>
        <Box
          sx={{
            position: "relative",
            zIndex: 5,
            flex: 1,
            display: "flex",
            pt: `${theme.header.height}`,
            pl: '75px',
            pb: '45px'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box flexGrow={1}>
              <TitleContext.Provider value={[title, setTitle]}>
                <StatusBar goalTarget={100} goalDays={122} planValue={27} />
                <Outlet />
              </TitleContext.Provider>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
