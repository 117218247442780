import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  checkboxClasses,
} from "@mui/material";
import arrowBack from "../../../../assets/img/layout/coach/MyAthletes/arrowBack.svg";
import EditIcon from "../../../../assets/img/layout/coach/Library/EditIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import PlansCalendarWidget from "./PlansCalendarWidget";
import PlansDescriptionComponent from "./PlansDescriptionComponent";
import RemoveWeekModal from "./RemoveWeekModal";
import * as yup from "yup";
import { useFormik } from "formik";
import SensorComponent from "./SensorComponent";
import { Calendar } from "react-date-range";
import "../../../../assets/scss/DatePicker.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import enGB from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import AddNewWorkoutModal from "./AddNewWorkoutModal";
import { SupabaseCall, sensorList, toHHMMSS } from "src/utils/common";
import { useSnackbar } from "notistack";
import { useUser } from "src/components/Authenticated";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, {
  isFulfilled,
  isPending,
  isUnloaded,
} from "src/utils/Async";

const dateDisplayStyle = `
  .rdrCalendarWrapper .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrCalendarWrapper .rdrStartEdge {
    color: #DD4F4A !important;
  }
  .rdrCalendarWrapper .rdrEndEdge {
    color: #DD4F4A !important;
  }
  .rdrCalendarWrapper .rdrInRange {
    color: #DD4F4A !important;
  }
  
  .rdrCalendarWrapper .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color : #DD4F4A !important;
  }
  `;

export type FormikProps = {
  name: string;
  description: string;
  image: string;
  price: number;
  sensors: Array<string>;
  ride_type: string;
  difficulty: string;
  available: boolean;
};

const populateSteps = (steps) => {
  if (!steps || steps.length === 0) return new Array(7).fill(undefined);
  const lastDay = steps[steps.length - 1]?.day || 0;
  const numberOfWeeks = Math.ceil(lastDay / 7);
  const emptySteps = new Array(numberOfWeeks * 7).fill(undefined);
  steps.forEach((step) => (emptySteps[step.day] = step.workout));
  return emptySteps;
};
export default function TrainingPlanDesigner({ trainingPlan }) {
  const supabase = useSupabase();
  const isTabletScreen = useMediaQuery("(max-width:1160px)");
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [imageFile, setImageFile] = useState<File>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rename, setRename] = useState<boolean>(false);
  const [selectedAthlete, setSelectedAthlete] = useState("");
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [radioValue, setRadioValue] = useState<string>("start");
  const [assignState, setAssignState] = useState<
    "nothing" | "athlete" | "date"
  >("nothing");
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>();
  const [steps, setSteps] = useState(
    populateSteps(trainingPlan?.training_plan_step),
  );
  const handleRadioValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRadioValue(event.target.value);
    },
    [],
  );
  const [isWorkoutModalOpen, setIsWorkoutModalOpen] = useState(false);
  const numberOfWeeks = useMemo(() => steps.length / 7, [steps]);
  const handleDate = useCallback(
    (date: Date) => {
      return radioValue === "start"
        ? (setStartDate(date),
          setEndDate(
            dayjs(date).add(numberOfWeeks, "week").subtract(1, "day").toDate(),
          ))
        : (setStartDate(
            dayjs(date).subtract(numberOfWeeks, "week").add(1, "day").toDate(),
          ),
          setEndDate(date));
    },
    [radioValue, numberOfWeeks],
  );
  useEffect(() => {
    if (assignState === "nothing") {
      setStartDate(new Date());
      setEndDate(
        dayjs().add(numberOfWeeks, "week").subtract(1, "day").toDate(),
      );
    }
  }, [assignState]);

  // Tabs Logic
  const handleChange = useCallback((event, newValue) => {
    setSelectedWeek(newValue);
  }, []);

  const addWeek = useCallback(() => {
    setSteps([...steps, ...new Array(7).fill(undefined)]);
  }, [steps]);
  const removeSelectedWeek = useCallback(() => {
    steps.splice(selectedWeek * 7, 7);
    setSteps([...steps]);
    setSelectedWeek(Math.max(0, selectedWeek - 1));
  }, [steps, selectedWeek]);

  useEffect(() => {
    setSelectedWeek(steps.length / 7 - 1);
  }, [numberOfWeeks]);

  // Formik
  const validationSchema = useMemo(() => yup.object().shape({}), []);
  const formik = useFormik<FormikProps>({
    initialValues: {
      name: trainingPlan?.name || "Training Plan",
      description: trainingPlan?.description || "",
      image: trainingPlan?.image || "",
      price: trainingPlan?.price || 0,
      sensors: trainingPlan?.sensors || [],
      ride_type: trainingPlan?.ride_type || "Indoor",
      difficulty: trainingPlan?.difficulty || "Beginner",
      available: trainingPlan?.available || false,
    },
    validationSchema,
    onSubmit: (values) => {
      if ("fire" in saveTrainingPlanCall) {
        saveTrainingPlanCall.fire(async () => saveTrainingPlanPromise(values));
      }
    },
  });

  const coachAthletesPromise = useCallback(
    () =>
      supabase
        .from("coach_athletes")
        .select("athlete(name,image,user_id)")
        .eq('status','Accepted')
        .eq("coach_id", user.id)
        .then((res) => res.data),
    [supabase],
  );

  const coachAthletesCall = useAsyncState<SupabaseCall<typeof coachAthletesPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(coachAthletesCall)) {
      coachAthletesCall.fire(async () => coachAthletesPromise());
    }
  }, [supabase, coachAthletesCall]);

  const applyTrainingPlanPromise = useCallback(
    async (values: FormikProps) => {
      const assignedTrainingPlan = await supabase;
      try {
        const assignedTrainingPlan = await supabase
          .from("assigned_training_plan")
          .insert({
            start_at: startDate.toISOString(),
            end_at: endDate.toISOString(),
            name: values.name,
            description: values.description,
            image: imageFile ? `${user.id}/${imageFile.name}` : values.image,
            ride_type: values.ride_type,
            difficulty: values.difficulty,
            sensors: values.sensors,
            price: values.price,
            assigned_by: user.id,
          })
          .select("id")
          .throwOnError();

        for (let i = 0; i < steps.length; i++) {
          if (!steps[i]) continue;

          const assignedWorkout = await supabase
            .from("assigned_workout")
            .insert({
              date: dayjs(startDate).add(i, "day").toISOString(),
              name: steps[i].name,
              workout_type: steps[i].workout_type,
              bike_type: steps[i].bike_type,
              duration: steps[i].duration,
              distance: steps[i].distance,
              tss: steps[i].tss,
              position_goal: steps[i].position_goal,
              user_id: selectedAthlete,
              assigned_training_plan_id: assignedTrainingPlan.data[0].id,
            })
            .select("id")
            .throwOnError();

          await supabase
            .from("assigned_workout_step")
            .insert(
              steps[i].workout_step.map((workout_step) => {
                delete workout_step.workout_id;
                delete workout_step.id;
                return {
                  ...workout_step,
                  assigned_workout_id: assignedWorkout.data[0].id,
                };
              }),
            )
            .throwOnError();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [supabase, steps, selectedAthlete, endDate, startDate],
  );

  const applyTrainingPlanCall =
    useAsyncState<SupabaseCall<typeof applyTrainingPlanPromise>>();

  useEffect(() => {
    if (isFulfilled(applyTrainingPlanCall)) {
      enqueueSnackbar("Training plan applied succesfully!", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }, [applyTrainingPlanCall]);

  const saveTrainingPlanPromise = useCallback(
    async (values: FormikProps) => {
      if (imageFile) {
        await supabase.storage
          .from("training-plan-images")
          .upload(`${user.id}/${imageFile.name}`, imageFile, {
            cacheControl: "3600",
            upsert: false,
          });
      }
      return supabase
        .from("training_plan")
        .upsert(
          {
            ...values,
            image: imageFile ? `${user.id}/${imageFile.name}` : values.image,
            id: trainingPlan?.id,
            user_id: user.id,
          },
          { onConflict: "id" },
        )
        .select("id")
        .throwOnError()
        .then(({ data }) =>
          supabase
            .from("training_plan_step")
            .delete()
            .eq("training_plan_id", data![0].id)
            .throwOnError()
            .then(() =>
              supabase
                .from("training_plan_step")
                .insert(
                  steps.reduce((results, step, i) => {
                    if (step) {
                      results.push({
                        day: i,
                        workout_id: step.id,
                        training_plan_id: data[0].id,
                      });
                    }
                    return results;
                  }, []),
                )
                .throwOnError(),
            ),
        );
    },
    [supabase, steps, imageFile],
  );

  const saveTrainingPlanCall =
    useAsyncState<SupabaseCall<typeof saveTrainingPlanPromise>>();

  useEffect(() => {
    if (isFulfilled(saveTrainingPlanCall)) {
      if (trainingPlan?.id) {
        enqueueSnackbar("Training Plan updated!", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar("New training plan added!", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          variant: "success",
          autoHideDuration: 2000,
        });
      }
      navigate("/coach/library/plans");
    }
  }, [saveTrainingPlanCall]);

  const handleSensor = useCallback(
    (id: string) => {
      formik.setFieldValue(
        "sensors",
        formik.values.sensors.includes(id)
          ? formik.values.sensors.filter((sensor) => sensor !== id)
          : formik.values.sensors.concat([id]),
      );
    },
    [formik.values.sensors],
  );

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: "100%",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
      },
    },
    grid: {
      show: false,
    },
    colors: ["#DD4F4A"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#C1C1C1",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(1);
        },
        style: {
          colors: "#C1C1C1",
        },
      },
    },
    title: {
      text: "Training Hours",
      style: {
        color: "#C1C1C1",
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // return `<div style="text-align: center; font-size:16px; color: red; padding: 5px; border-radius: 5px;">${series[seriesIndex][dataPointIndex]}</div>`;
        return `<div style="text-align: center; font-size:16px; color: red; padding: 5px; border-radius: 5px;">Workouts :  ${(
          numberOfSessions / numberOfWeeks
        ).toFixed(0)} </div>`;
      },
    },
  };
  const chartSeries: ApexAxisChartSeries = useMemo(() => {
    if (numberOfWeeks < 1) return [{ data: [] }];
    return [
      {
        data: [...Array(numberOfWeeks)].map((_, i) => ({
          y: (
            steps
              .slice(i * 7, i * 7 + 7)
              .reduce((acc, curr) => acc + (curr?.duration || 0), 0) / 3600
          ).toFixed(2),
          x: `Week ${i + 1}`,
        })),
      },
    ];
  }, [numberOfWeeks, steps]);

  const totalTime = useMemo(
    () => steps.reduce((acc, curr) => acc + (curr?.duration || 0), 0),
    [steps],
  );
  const longestStep = useMemo(
    () => steps.reduce((acc, curr) => Math.max(acc, curr?.duration || 0), 0),
    [steps],
  );
  const numberOfSessions = useMemo(
    () => steps.filter((step) => step !== undefined).length,
    [steps],
  );

  function handleImageChange(e) {
    if (!e.target.files[0]) return;
    formik.setFieldValue("image", URL.createObjectURL(e.target.files[0]));
    setImageFile(e.target.files[0]);
  }
  //Avatar
  const avatar = useMemo(() => {
    return createAvatar(initials, {
      size: 20,
      radius: 50,
      seed: "AA",
    }).toDataUriSync();
  }, []);

  const totalTss = useMemo(() => {
    let accTss = 0;
    for (let i = 0; i < steps.length; i++) {
      if (!steps[i]) continue;
      accTss += steps[i].tss || 0;
    }
    return accTss;
  }, [steps]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: "96%",
            padding: "1em",
            background: "#201B20",
            borderRadius: ".625rem",
            marginTop: ".625rem",
            marginLeft: "1.5625rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ padding: ".125rem" }}
              onClick={() => navigate("/coach/library/plans")}
            >
              <img src={arrowBack} />
            </IconButton>
            <Typography>Back to Library</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
              marginRight: "1.25rem",
            }}
          >
            <Button
              variant="coachVariant2"
              size="small"
              onClick={() => navigate("/coach/library")}
            >
              Cancel
            </Button>
            <Button
              variant="coachVariant"
              size="small"
              type="submit"
              disabled={isPending(saveTrainingPlanCall)}
            >
              Save
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: "1.875rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                paddingX: "0.5rem",
              }}
            >
              {rename ? (
                <FormControl
                  sx={{
                    "& .MuiFormControl-root": {
                      width: "250px",
                    },
                  }}
                >
                  <TextField
                    id="name"
                    name="name"
                    value={formik.values.name ?? ""}
                    onChange={formik.handleChange}
                    onBlur={() => setRename(false)}
                    autoFocus
                    onKeyDown={(e) =>
                      e.code === "Enter" ? setRename(false) : ""
                    }
                    sx={{
                      border: "1px solid #64605D",
                      borderRadius: "4px",
                      borderRightColor: "#A19D9A",
                      "& .MuiInputBase-root": {
                        width: "250px",
                        fontSize: "18px",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DD4F4A !important",
                      },
                    }}
                  >
                    {formik.values.name}
                  </TextField>
                </FormControl>
              ) : (
                <Typography variant="h2">{formik.values.name}</Typography>
              )}
              {rename ? null : (
                <Tooltip title="Rename" placement="top" arrow>
                  <img
                    src={EditIcon}
                    width="14"
                    height="14"
                    onClick={() => setRename(true)}
                  />
                </Tooltip>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
              }}
            >
              {/* Left Side */}
              <Box sx={{ width: "65%" }}>
                <Typography
                  sx={{
                    fontSize: ".9375rem",
                    fontWeight: 700,
                    paddingLeft: "8px",
                    marginBottom: "16px",
                  }}
                >
                  Plan Description
                </Typography>
                <PlansDescriptionComponent formik={formik} />
                {isTabletScreen && (
                  <>
                    <Typography
                      sx={{
                        fontSize: ".9375rem",
                        fontWeight: 700,
                        paddingLeft: "8px",
                        marginY: "16px",
                      }}
                    >
                      Cover Image
                    </Typography>
                    <Box
                      sx={{
                        border: "1px dashed #64605D",
                        borderRadius: "6px",
                        width: "95%",
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {formik.values.image === "" ? (
                        <>
                          <label>
                            <AddCircleOutlineOutlinedIcon
                              sx={{
                                padding: "15px",
                                color: "#64605D",
                                cursor: "pointer",
                                borderRadius: "50%",
                                "&:hover": {
                                  background: "rgba(255, 255, 255, 0.1)",
                                },
                              }}
                              style={{ fontSize: 80 }}
                            />
                            <input
                              style={{ display: "none" }}
                              type="file"
                              onChange={handleImageChange}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </label>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#64605D" }}
                          >
                            Upload cover image or Drag and Drop here
                          </Typography>
                        </>
                      ) : (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={
                            formik.values.image.startsWith("blob:")
                              ? formik.values.image
                              : `${process.env.REACT_APP_SUPABASE_DOMAIN}/storage/v1/object/public/training-plan-images/${formik.values.image}`
                          }
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        paddingY: "10px",
                        visibility:
                          formik.values.image !== "" ? "initial" : "hidden",
                      }}
                    >
                      <Button
                        variant="coachVariant2"
                        sx={{ border: "none", color: "#fff", padding: "0px" }}
                      >
                        <label
                          htmlFor="trainingPlanImage"
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: "9px 16px 9px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            id="trainingPlanImage"
                            style={{ display: "none" }}
                            type="file"
                            onChange={handleImageChange}
                            accept="image/png, image/gif, image/jpeg"
                          />
                          Replace
                        </label>
                      </Button>
                      <Button
                        sx={{ border: "none", color: "#DD4F4A" }}
                        onClick={() => {
                          setImageFile(undefined);
                          formik.setFieldValue("image", "");
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                    <Divider
                      sx={{
                        width: "100%",
                        border: "1px solid #64605D",
                        marginBottom: "2.2rem",
                      }}
                    />
                  </>
                )}
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Training Plan Sample
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        paddingX: "1em",
                      }}
                    >
                      <Tabs
                        value={selectedWeek}
                        onChange={handleChange}
                        variant="scrollable"
                        sx={{
                          "& .MuiTab-root:not(.Mui-selected)": {
                            opacity: 0.5,
                          },
                          minHeight: "1.5rem",
                          "& .Mui-selected": {
                            color: "#DD4F4A !important",
                          },
                          width: "100%",
                          marginBottom: "1rem",
                        }}
                        TabIndicatorProps={{
                          sx: {
                            backgroundColor: "#DD4F4A",
                          },
                        }}
                      >
                        {[...Array(numberOfWeeks)].map((tab, index) => (
                          <Tab
                            label={`Week ${index + 1}`}
                            key={index}
                            sx={{ width: "20%" }}
                          />
                        ))}
                      </Tabs>
                      <PlansCalendarWidget
                        steps={steps}
                        setSteps={setSteps}
                        selectedWeek={selectedWeek}
                        setIsModalOpen={setIsWorkoutModalOpen}
                        setSelectedDay={setSelectedDay}
                      />
                    </Box>

                    <Box
                      sx={{
                        background: "#24211F",
                        borderRadius: "8px",
                        padding: "2em",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "90%",
                      }}
                    >
                      <Typography variant="h4" sx={{ color: "#DD4F4A" }}>
                        {toHHMMSS(totalTime)}
                      </Typography>
                      <Typography variant="h4" sx={{ color: "#E28E54" }}>
                        {totalTss} TSS
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignSelf: "flex-end",
                        marginY: "1.5rem",
                        paddingRight: isTabletScreen ? "0.7rem" : "1.5rem",
                      }}
                    >
                      <Button
                        variant="coachVariant2"
                        onClick={() => setIsModalOpen(true)}
                        disabled={numberOfWeeks === 1}
                      >
                        Remove Week
                      </Button>
                      <RemoveWeekModal
                        open={isModalOpen}
                        onConfirm={() => {
                          removeSelectedWeek();
                          setIsModalOpen(false);
                        }}
                        onCancel={() => setIsModalOpen(false)}
                        weekNumber={selectedWeek + 1}
                      />
                      <Button variant="coachVariant2" onClick={addWeek}>
                        + Add Week
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Stats
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    height: isTabletScreen ? "380px" : "350px",
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      padding: "2em",
                    }}
                  >
                    <Typography variant="h5">
                      AVERAGE WEEKLY BREAKDOWN
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          variant={isTabletScreen ? "subtitle1" : "h4"}
                        >
                          Workouts Per Week
                        </Typography>
                        <Typography variant="h4" color={"#DD4F4A"}>
                          {(numberOfSessions / numberOfWeeks).toFixed(1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          variant={isTabletScreen ? "subtitle1" : "h4"}
                        >
                          Weekly Avg.
                        </Typography>
                        <Typography variant="h4" color="#DD4F4A">
                          {toHHMMSS(totalTime / numberOfWeeks)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Typography
                          variant={isTabletScreen ? "subtitle1" : "h4"}
                        >
                          Longest Workout
                        </Typography>
                        <Typography variant="h4" color="#DD4F4A">
                          {toHHMMSS(longestStep)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ height: isTabletScreen ? "180px" : "200px" }}>
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="bar"
                        height={"100%"}
                      />
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Suggested Devices
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    padding: "2em",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {sensorList.map((sensor, index) => (
                    <SensorComponent
                      key={index}
                      sensor={sensor}
                      formik={formik.values.sensors}
                      handleSensor={handleSensor}
                    />
                  ))}
                </Box>
              </Box>
              {/* Right Side */}

              <Box sx={{ width: "35%", paddingLeft: "3rem" }}>
                {!isTabletScreen && (
                  <>
                    <Typography variant="h4" marginBottom={"0.5rem"}>
                      Cover Image
                    </Typography>
                    <Box
                      sx={{
                        border: "1px dashed #64605D",
                        borderRadius: "6px",
                        width: "95%",
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {formik.values.image === "" ? (
                        <>
                          <label>
                            <AddCircleOutlineOutlinedIcon
                              sx={{
                                padding: "15px",
                                color: "#64605D",
                                cursor: "pointer",
                                borderRadius: "50%",
                                "&:hover": {
                                  background: "rgba(255, 255, 255, 0.1)",
                                },
                              }}
                              style={{ fontSize: 80 }}
                            />
                            <input
                              style={{ display: "none" }}
                              type="file"
                              onChange={handleImageChange}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </label>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#64605D" }}
                          >
                            Upload cover image or Drag and Drop here
                          </Typography>
                        </>
                      ) : (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={
                            formik.values.image.startsWith("blob:")
                              ? formik.values.image
                              : `${process.env.REACT_APP_SUPABASE_DOMAIN}/storage/v1/object/public/training-plan-images/${formik.values.image}`
                          }
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        paddingY: "10px",
                        visibility:
                          formik.values.image !== "" ? "initial" : "hidden",
                      }}
                    >
                      <Button
                        variant="coachVariant2"
                        sx={{ border: "none", color: "#fff", padding: "0px" }}
                      >
                        <label
                          htmlFor="trainingPlanImage"
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: "9px 16px 9px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            id="trainingPlanImage"
                            style={{ display: "none" }}
                            type="file"
                            onChange={handleImageChange}
                            accept="image/png, image/gif, image/jpeg"
                          />
                          Replace
                        </label>
                      </Button>
                      <Button
                        sx={{ border: "none", color: "#DD4F4A" }}
                        onClick={() => {
                          setImageFile(undefined);
                          formik.setFieldValue("image", "");
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                    <Divider
                      sx={{
                        width: "95%",
                        border: "1px solid #64605D",
                        marginBottom: "2.2rem",
                      }}
                    />
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Ride Type</Typography>
                  <FormControl>
                    <Select
                      id="ride_type"
                      name="ride_type"
                      value={formik.values.ride_type}
                      onChange={formik.handleChange}
                      sx={{
                        color: "#F2F2F2",
                        "& .MuiSvgIcon-root": {
                          color: "#F2F2F2",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#64605D",
                          },
                          "&:hover fieldset": {
                            borderColor: "#64605D",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#64605D",
                          },
                          width: isTabletScreen ? 130 : 280,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: ".0625rem solid #64605D",
                          },
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "#64605D",
                            },
                          },
                        },
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      <MenuItem value="Indoor">Indoor</MenuItem>
                      <MenuItem value="Outdoor">Outdoor</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Difficulty</Typography>
                  <FormControl>
                    <Select
                      id="difficulty"
                      name="difficulty"
                      value={formik.values.difficulty}
                      onChange={formik.handleChange}
                      sx={{
                        color: "#F2F2F2",
                        "& .MuiSvgIcon-root": {
                          color: "#F2F2F2",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#64605D",
                          },
                          "&:hover fieldset": {
                            borderColor: "#64605D",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#64605D",
                          },
                          width: isTabletScreen ? 130 : 280,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: ".0625rem solid #64605D",
                          },
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "#64605D",
                            },
                          },
                        },
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      <MenuItem value="Beginner">Beginner</MenuItem>
                      <MenuItem value="Intermediate">Intermediate</MenuItem>
                      <MenuItem value="Expert">Expert</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Length</Typography>
                  <Typography variant="h2" color={"#64605D"}>
                    {numberOfWeeks} {numberOfWeeks === 1 ? "Week" : "Weeks"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Number of sessions</Typography>
                  <Typography variant="h2" color={"#64605D"}>
                    {numberOfSessions}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  {/* <Typography variant="h4">Price</Typography> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <FormControl
                      sx={{
                        "& .MuiFormControl-root": {
                          width: "120px",
                        },
                      }}
                    >
                      <TextField
                        name="price"
                        type="number"
                        //   onBlur={formik.handleBlur}
                        value={formik.values.price}
                        //   error={
                        //     formik.touched.primary_target_value &&
                        //     Boolean(formik.errors.primary_target_value)
                        //   }
                        //   helperText={
                        //     formik.touched.primary_target_value &&
                        //     formik.errors.primary_target_value
                        //   }
                        onChange={formik.handleChange}
                        sx={{
                          border: "1px solid #64605D",
                          borderRadius: "4px",
                          borderRightColor: "#A19D9A",
                          "& .MuiInputBase-root": {
                            width: "120px",
                            textAlign: "center", // Align text center
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DD4F4A !important",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ marginLeft: "0.5rem", color: "#64605D" }}
                            >
                              <Typography variant="h4">€</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl> */}
                    <Checkbox
                      name="available"
                      checked={formik.values.available}
                      onChange={formik.handleChange}
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: "#DD4F4A",
                        },
                      }}
                    />
                    <Typography>Available on Store</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "2rem",
                  }}
                >
                  <Typography variant="h4">Last Updated</Typography>
                  <Typography variant="h2" color={"#64605D"}>
                    {trainingPlan?.updated_at
                      ? dayjs(trainingPlan.updated_at).format("DD MMM YYYY")
                      : "--"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    marginBottom: "1rem",
                    position: "relative",
                  }}
                >
                  <Button
                    variant="coachVariant"
                    sx={{ width: "95%", marginTop: "1rem" }}
                    onClick={() => setAssignState("athlete")}
                    disabled={isPending(applyTrainingPlanCall)}
                  >
                    Assign To
                  </Button>
                  <Button
                    variant="coachVariant2"
                    onClick={() => setOpen(true)}
                    sx={{
                      width: "95%",
                      marginTop: "0.5rem",
                      fontSize: isTabletScreen ? 13 : "",
                    }}
                  >
                    Delete Plan
                  </Button>
                  <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                      "& .MuiPaper-root": {
                        background: "#201C20",
                      },
                      "& .MuiBackdrop-root": {
                        backgroundColor: "transparent",
                      },
                    }}
                    PaperProps={{
                      style: {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <DialogTitle>
                      {"Are you sure you want to delete the training plan?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText sx={{ color: "#dd4f4a" }}>
                        This action cannot be undone. Please confirm if you want
                        to proceed with deleting the training plan.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpen(false)}
                        variant="coachVariant2"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => console.log("delete")}
                        variant="coachVariant"
                        autoFocus
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Box
                    sx={{
                      backgroundColor: "#2A252B",
                      zIndex: 20,
                      bottom: 0,
                      right: isTabletScreen ? 0.5 : "",
                      borderRadius: "20px 20px 0px 0px",
                      position: "absolute",
                    }}
                  >
                    {assignState === "athlete" ? (
                      <Box
                        sx={{
                          width: { md: "400px", lg: "383px", xl: "700px" },
                          borderRadius: "20px",
                          border: "3px solid",
                          borderColor: "#DD4F4A",
                          padding: "1.5em",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "2rem",
                          }}
                        >
                          <Typography
                            variant={isTabletScreen ? "h2" : "h1"}
                            color={"#DD4F4A"}
                          >
                            Assign to athlete
                          </Typography>
                          <IconButton
                            disableRipple
                            size="small"
                            onClick={() => setAssignState("nothing")}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>

                        <>
                          <FormControl
                            sx={{
                              maxHeight: "350px",
                              overflowY: "auto",
                            }}
                          >
                            <RadioGroup
                              value={selectedAthlete}
                              onChange={(e) =>
                                setSelectedAthlete(e.target.value)
                              }
                            >
                              {isFulfilled(coachAthletesCall) &&
                                coachAthletesCall.result.map(
                                  (coachAthlete, index) => {
                                    const avatar =  coachAthlete.athlete.image ?
                                      supabase.storage.from('avatars').getPublicUrl(coachAthlete.athlete.image, {
                                        transform: {
                                          width: 50,
                                          height: 50,
                                        },
                                      }).data.publicUrl : 
                                      createAvatar(initials, {
                                        size: 50,
                                        radius: 55,
                                        seed: coachAthlete.athlete.name,
                                      }).toDataUriSync()

                                    return (
                                      <FormControlLabel
                                        key={index}
                                        value={coachAthlete.athlete.user_id}
                                        control={
                                          <Radio
                                            sx={{
                                              marginLeft: "10px",
                                              color: "#DD4F4A",
                                              "&.Mui-checked": {
                                                color: "#DD4F4A",
                                              },
                                            }}
                                            size="small"
                                          />
                                        }
                                        label={
                                          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography>{coachAthlete.athlete.name}</Typography>
                                            <img src={avatar} alt="avatar" style={{borderRadius: '50%', width: '30px', height: '30px', objectFit: 'cover'}}/>
                                          </Box>
                                        }
                                        sx={{
                                          borderRadius: "10px",
                                          marginBottom: "0.5rem",
                                          fontSize: "14px",
                                          "& .MuiFormControlLabel-label": {
                                            width: '100%'
                                          }
                                        }}
                                      />
                                    )
                                  },
                                )}
                            </RadioGroup>
                          </FormControl>
                          <Button
                            variant="coachVariant"
                            onClick={() => setAssignState("date")}
                            disabled={selectedAthlete === ""}
                          >
                            Assign
                          </Button>
                        </>
                      </Box>
                    ) : assignState === "date" ? (
                      <Box
                        sx={{
                          width: { md: "383px", xl: "700px" },
                          borderRadius: "20px",
                          border: "3px solid",
                          borderColor: "#DD4F4A",
                          padding: "1.5em",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <style>{dateDisplayStyle}</style>
                        <FormControl sx={{ position: "relative" }}>
                          <IconButton
                            disableRipple
                            size="small"
                            onClick={() => setAssignState("nothing")}
                            sx={{ position: "absolute", top: 0, right: 0 }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <FormLabel sx={{ alignSelf: "center" }}>
                            <Typography color={"#DD4F4A"}>
                              Apply Range Dates By
                            </Typography>
                          </FormLabel>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              marginBottom: "15px",
                            }}
                          >
                            <RadioGroup
                              row
                              name="row-radio-buttons-group"
                              value={radioValue}
                              onChange={handleRadioValue}
                            >
                              <FormControlLabel
                                value="start"
                                control={
                                  <Radio
                                    sx={{
                                      color: "#DD4F4A",
                                      "&.Mui-checked": {
                                        color: "#DD4F4A",
                                      },
                                    }}
                                  />
                                }
                                label="Start Date"
                              />
                              <FormControlLabel
                                value="end"
                                control={
                                  <Radio
                                    sx={{
                                      color: "#DD4F4A",
                                      "&.Mui-checked": {
                                        color: "#DD4F4A",
                                      },
                                    }}
                                  />
                                }
                                label="End Date"
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>
                        <Box sx={{ display: "flex", alignSelf: "center" }}>
                          <Calendar
                            onChange={(date) => handleDate(date)}
                            date={radioValue === "start" ? startDate : endDate}
                            minDate={new Date()}
                            editableDateInputs
                            locale={enGB}
                            preview={{
                              startDate: startDate,
                              endDate: endDate,
                              color: "#DD4F4A",
                            }}
                            color="#DD4F4A"
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          marginY={"15px"}
                          textAlign={"center"}
                        >
                          Dates Selected :{" "}
                          {dayjs(startDate).format("DD/MM/YYYY")} -{" "}
                          {dayjs(endDate).format("DD/MM/YYYY")}
                        </Typography>

                        <Button
                          variant="coachVariant"
                          onClick={() => {
                            if ("fire" in applyTrainingPlanCall) {
                              applyTrainingPlanCall.fire(async () =>
                                applyTrainingPlanPromise(formik.values),
                              );
                            }
                            setAssignState("nothing");
                          }}
                          disabled={isPending(applyTrainingPlanCall)}
                        >
                          Apply
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
      <AddNewWorkoutModal
        selectedDay={selectedDay}
        steps={steps}
        setSteps={setSteps}
        open={isWorkoutModalOpen}
        setOpen={setIsWorkoutModalOpen}
      />
    </>
  );
}
