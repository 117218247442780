import { Box, useTheme } from "@mui/material";

import { createContext, useContext, useState } from "react";

import { Outlet } from "react-router-dom";
import CoachNavbar from "./coachNavbar";
import CoachStatusBar from "./coachStatusBar";
import CoachSidebar from "./coachSidebar";
import Footer from "src/content/customComponents/dashboard/Footer";


export const TitleContextCoach =
  createContext<[string, (newTitle: string) => void]>(undefined);

export const useTitleCoach = () => useContext(TitleContextCoach);

export default function CoachDashboardLayout() {
  const theme = useTheme();
  const [title, setTitle] = useState<string>("Coach");

  return (
    <>
      <CoachNavbar />
      <CoachSidebar />
      <Box>
        <Box
          sx={{
            position: "relative",
            zIndex: 5,
            flex: 1,
            display: "flex",
            pt: `${theme.header.height}`,
            pl: "185px",
            pb: '45px'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box flexGrow={1}>
              <TitleContextCoach.Provider value={[title, setTitle]}>
                <CoachStatusBar />
                <Outlet />
              </TitleContextCoach.Provider>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}