import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

//Date

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "../../assets/scss/Calendar.scss";

import convert from "convert-units";
import { useFormik } from "formik";
import { Dispatch, useEffect, useRef, useState } from "react";
import { useSession, useSupabase } from "src/contexts/SupabaseContext";
import { isFulfilled } from "src/utils/Async";
import { imperialToCentimeters } from "src/utils/common";
import * as yup from "yup";

export type PersonalSetupInfoType = {
  image?: string;
  name: string;
  dateofbirth: Date;
  gender: "male" | "female";
  weight: number;
  height: number;
  units: "metric" | "imperial";
  weight_pounds: number;
  height_feet: number;
  height_inches: number;
};

export type PersonalSetupInfoProps = {
  onSubmit: (validatedPersonalSetupInfo: PersonalSetupInfoType) => void;
  onBack: () => void;
  onChange: (currentPersonalSetupInfo: PersonalSetupInfoType) => void;
  initialValues?: PersonalSetupInfoType;
  setImageFile: Dispatch<File>
  imageFile?: File
};

export default function PersonalSetupInfo(props: PersonalSetupInfoProps) {
  const [openDate, setOpenDate] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:450px)");
  const inputImageRef = useRef<HTMLInputElement>();
  const buttonRef = useRef(null);

  const sessionData = useSession();

  const formik = useFormik<PersonalSetupInfoType>({
    initialValues: props.initialValues ?? {
      name: "",
      dateofbirth: null,
      gender: null,
      weight: null,
      height: null,
      units: "metric",
      weight_pounds: null,
      height_feet: null,
      height_inches: null,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      dateofbirth: yup
        .date()
        .max(new Date(Date.now() - 378683112000), "Minimum age is 12 years old")
        .min(
          new Date(Date.now() - 3155692600000.1),
          "Maximum age is 100 years old",
        )
        .required("Date of birth is required"),
      gender: yup
        .string()
        .oneOf(["male", "female"], "Please select a valid gender")
        .required("Please select a valid gender"),
      weight: yup
        .number()
        .test(
          "minimum metric weight",
          "Weight cannot be lower than 30kg",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.weight >= 30,
        )
        .test(
          "maximum metric weight",
          "Weight cannot be greater than 250kg",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.weight <= 250,
        )
        .test(
          "metric weight is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "imperial",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      height: yup
        .number()
        .test(
          "minimum metric height",
          "Height cannot be lower than 100 cm",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.height >= 100,
        )
        .test(
          "maximum metric height",
          "Height cannot be greater than 230 cm",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.height <= 230,
        )
        .test(
          "metric height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "imeprial",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      height_inches: yup
        .number()
        .min(0, "Inches should be positive")
        .max(11, "Inches should be less than 11")
        .test(
          "imperial height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      height_feet: yup
        .number()
        .test(
          "minimum imperial height",
          "Height cannot be lower than 3ft 4in",
          (value, { parent }) =>
            !value && !parent.height_inches && parent.height_inches !== 0
              ? true
              : value +
                  (!!parent.height_inches
                    ? convert(parent.height_inches).from("in").to("ft")
                    : 0) >=
                3 + 1 / 3,
        )
        .test(
          "maximum imperial height",
          "Height cannot be greater than 7ft 6in",
          (value, { parent }) =>
            !value ||
            parent.units === "metric" ||
            imperialToCentimeters(parent.height_feet, parent.height_inches) <= 230,
        )
        .test(
          "imperial height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      weight_pounds: yup
        .number()
        .test(
          "minimum imperial weight",
          "Weight cannot be lower than 66lbs",
          (value, { parent }) =>
            !value || parent.units === "metric" || parent.weight_pounds >= 66,
        )
        .test(
          "maximum imperial weight",
          "Weight cannot be greater than 551lbs",
          (value, { parent }) =>
            !value || parent.units === "metric" || parent.weight_pounds <= 551,
        )
        .test(
          "imperial weight is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      units: yup
        .string()
        .oneOf(["metric", "imperial"], "Please select a valid unit"),
    }),
    onSubmit: async (values) => {
      values.weight =
        values.units === "imperial" && values.weight_pounds !== null
          ? Math.round(convert(values.weight_pounds).from("lb").to("kg"))
          : values.weight;
      values.height =
        values.units === "imperial" && values.height_feet !== null
          ? Number(
              imperialToCentimeters(
                values.height_feet,
                values.height_inches,
              ).toFixed(2),
            )
          : values.height;
      props.onSubmit(values);
    },
  });

  function handleImageChange(e) {
    if (!e.target.files[0]) return;
    props.setImageFile(e.target.files[0]);
  }
  
  // OTAN ALLAKSEI TO A KAME VALIDATE TO B
  useEffect(() => {
    formik.validateField("height_feet"); // EIMAI TO B
  }, [formik.values.height_inches]); // EIMAI TO A

  useEffect(() => {
    if (isFulfilled(sessionData)) {
      if (!!sessionData?.result?.data?.session?.user?.user_metadata?.name)
        formik.setFieldValue(
          "name",
          sessionData.result.data.session.user.user_metadata.name,
        );
    }
  }, [sessionData]);

  useEffect(() => {
    props.onChange(formik.values);
  }, [formik.values]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit} style={{display: 'flex', gap: isMobileScreen ? '1rem' : '5rem', alignItems: 'center', flexDirection: isMobileScreen ? 'column-reverse' : 'row'}}>
        <Box marginTop={"40px"}>
          <Box>
            <Typography
              variant="h1"
              color="text.secondary"
              sx={{ mb: ".5rem" }}
            >
              Profile Setup
            </Typography>
            <Typography
              variant="body1"
              fontSize={"13px"}
              lineHeight={"15px"}
              pl={".1875rem"}
            >
              Personal Information
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1.5rem",
              mt: "1.5rem",
            }}
          >
            <FormControl>
              <TextField
                name="name"
                label="Full Name *"
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
            <FormControl>
              <TextField
                label="Date of birth *"
                name="dateofbirth"
                error={
                  formik.touched.dateofbirth &&
                  Boolean(formik.errors.dateofbirth)
                }
                helperText={
                  formik.touched.dateofbirth && formik.errors.dateofbirth
                }
                value={
                  !!formik.values.dateofbirth
                    ? dayjs(formik.values.dateofbirth).format("DD/MM/YYYY")
                    : ""
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setOpenDate(!openDate)}
                        ref={buttonRef}
                      >
                        <CalendarMonthIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Popover
              open={openDate}
              anchorEl={buttonRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={(_) => setOpenDate(false)}
              PaperProps={{
                style: {
                  background: "none",
                },
              }}
            >
              <Calendar
                onChange={(date) => {
                  formik.setFieldTouched("dateofbirth");
                  formik.setFieldValue("dateofbirth", date);
                }}
                date={formik.values.dateofbirth}
                maxDate={new Date()}
              />
            </Popover>
            <FormControl fullWidth>
              <InputLabel id="gender">Gender *</InputLabel>
              <Select
                id="gender"
                name="gender"
                value={formik.values.gender ?? ""}
                onChange={formik.handleChange}
                label="Gender *"
                sx={{
                  "& .MuiSelect-icon": { marginRight: ".4375rem" },
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
            </FormControl>
            {formik.values.units === "metric" ? (
              <TextField
                error={formik.touched.weight && Boolean(formik.errors.weight)}
                helperText={formik.touched.weight && formik.errors.weight}
                label="Weight (kg)"
                name="weight"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                value={formik.values.weight ?? ""}
                variant="outlined"
              />
            ) : (
              <TextField
                error={
                  formik.touched.weight_pounds &&
                  Boolean(formik.errors.weight_pounds)
                }
                helperText={
                  formik.touched.weight_pounds && formik.errors.weight_pounds
                }
                label="Weight (lb)"
                name="weight_pounds"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                value={formik.values.weight_pounds ?? ""}
                variant="outlined"
              />
            )}
            {formik.values.units === "metric" ? (
              <TextField
                error={formik.touched.height && Boolean(formik.errors.height)}
                helperText={formik.touched.height && formik.errors.height}
                name="height"
                label="Height (cm)"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                value={formik.values.height ?? ""}
                variant="outlined"
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: isMobileScreen ? "column" : "row",
                  gap: isMobileScreen ? 2 : 0,
                }}
              >
                <TextField
                  error={
                    formik.touched.height_feet &&
                    Boolean(formik.errors.height_feet)
                  }
                  helperText={
                    formik.touched.height_feet && formik.errors.height_feet
                  }
                  label="Height (ft)"
                  name="height_feet"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.height_feet ?? ""}
                  variant="outlined"
                  sx={{
                    width: "10rem",
                  }}
                />
                <TextField
                  label="Height (in)"
                  name="height_inches"
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.height_inches && formik.errors.height_inches,
                  )}
                  helperText={
                    formik.touched.height_inches && formik.errors.height_inches
                  }
                  onChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                    if (e.target.value === "") {
                      formik.setFieldValue("height_inches", null);
                    }
                  }}
                  value={formik.values.height_inches ?? ""}
                  variant="outlined"
                  sx={{
                    width: "10rem",
                    "& .MuiFormHelperText-root": { width: "max-content" },
                  }}
                />
              </Box>
            )}
          </Box>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "1.25rem",
              justifyContent: "space-around",
            }}
          >
            <FormLabel sx={{ alignSelf: "center" }}>
              <Typography
                color="text.primary"
                sx={{ opacity: "0.5", fontSize: { xs: "12px", xl: "12px" } }}
              >
                Units of Measure
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              onChange={formik.handleChange}
              value={formik.values.units}
              name="units"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                "& .MuiFormControlLabel-label": { opacity: 0.5 },
              }}
            >
              <FormControlLabel
                value="metric"
                control={<Radio color="primary" />}
                label={<span style={{ fontSize: "12px" }}>Metric</span>}
              />
              <FormControlLabel
                value="imperial"
                control={<Radio color="primary" />}
                label={<span style={{ fontSize: "12px" }}>Imperial</span>}
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              mt: "1.375rem",
            }}
          >
            <Button
              onClick={props.onBack}
              size="small"
              variant="outlined"
              sx={{ width: "9.75rem", lineHeight: "1rem" }}
            >
              Back
            </Button>

            <Button
              disabled={
                !(formik.isValid && (formik.dirty || !!props.initialValues))
              }
              variant="contained"
              size="small"
              type="submit"
              sx={{ width: "9.75rem", lineHeight: "1rem", ml: "1rem" }}
            >
              Next
            </Button>
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'1rem'}>
          <Box
            sx={{
              position: 'relative',
              border: props.imageFile ? "none" : "1px dashed #64605D",
              borderRadius: "50%",
              width: "150px",
              height: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: props.imageFile ? `url(${URL.createObjectURL(props.imageFile)})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            {
              props.imageFile ? (
                <button
                  onClick={() => props.setImageFile(undefined)}
                  type="button"
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: '#FFF',
                    border: 'none',
                    padding: 0,
                    background: "none",
                    cursor: "pointer",
                    fontSize: '20px'
                  }}
                >
                  &times;
                </button>
              ) : null
            }
          </Box>
          <input
            ref={inputImageRef}
            style={{ display: "none" }}
            type="file"
            onChange={handleImageChange}
            accept="image/png, image/gif, image/jpeg"
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => inputImageRef.current.click()}
            sx={{ width: "9.75rem", lineHeight: "1rem" }}
          >
            Select Image
          </Button>
        </Box>
      </form>
    </>
  );
}
